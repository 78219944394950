import React from 'react';
import PropertyDemo, { demoKey } from '../components/PropertyDemo';

export default function ({}) {
  return (
    <PropertyDemo
      profileKey={demoKey.demo2.key}
      demoName={demoKey.demo2.title}
    />
  );
}
